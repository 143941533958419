// Generated by Framer (21ec140)

import { addFonts, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Et0wr1IIY"];

const serializationHash = "framer-yEoQP"

const variantClassNames = {Et0wr1IIY: "framer-v-1yuf1zp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Y1jKtkJjZZ5xwzpHRd, HmWXS9X01Z5xwzpHRd, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Et0wr1IIY", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1yuf1zp", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Et0wr1IIY"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1v2r44"} data-framer-name={"Text"} layoutDependency={layoutDependency} layoutId={"h0O6YNVNE"}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW5zdHJ1bWVudCBTYW5zLTYwMA==", "--framer-font-family": "\"Instrument Sans\", \"Instrument Sans Placeholder\", sans-serif", "--framer-font-size": "calc(var(--variable-reference-HmWXS9X01-Z5xwzpHRd) * 1px)", "--framer-font-weight": "600", "--framer-letter-spacing": "0px", "--framer-line-height": "20px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, var(--variable-reference-Y1jKtkJjZ-Z5xwzpHRd))", "--framer-text-transform": "uppercase"}}>Linkedin</motion.p></React.Fragment>} className={"framer-1la03ha"} data-framer-name={"Link"} fonts={["GF;Instrument Sans-600"]} layoutDependency={layoutDependency} layoutId={"Hvm4qCCEN"} style={{"--extracted-r6o4lv": "var(--variable-reference-Y1jKtkJjZ-Z5xwzpHRd)", "--variable-reference-HmWXS9X01-Z5xwzpHRd": HmWXS9X01Z5xwzpHRd, "--variable-reference-Y1jKtkJjZ-Z5xwzpHRd": Y1jKtkJjZZ5xwzpHRd}} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-1v44d3z"} layoutDependency={layoutDependency} layoutId={"AgA3diLAd"} style={{backgroundColor: "rgb(29, 29, 31)"}}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yEoQP.framer-jl3zun, .framer-yEoQP .framer-jl3zun { display: block; }", ".framer-yEoQP.framer-1yuf1zp { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 21px; justify-content: center; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-yEoQP .framer-1v2r44 { align-content: flex-start; align-items: flex-start; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 100%; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-yEoQP .framer-1la03ha { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-yEoQP .framer-1v44d3z { bottom: 0px; flex: none; height: 2px; left: -1px; overflow: hidden; position: absolute; width: 1px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-yEoQP.framer-1yuf1zp, .framer-yEoQP .framer-1v2r44 { gap: 0px; } .framer-yEoQP.framer-1yuf1zp > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-yEoQP.framer-1yuf1zp > :first-child { margin-left: 0px; } .framer-yEoQP.framer-1yuf1zp > :last-child { margin-right: 0px; } .framer-yEoQP .framer-1v2r44 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-yEoQP .framer-1v2r44 > :first-child { margin-top: 0px; } .framer-yEoQP .framer-1v2r44 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 21
 * @framerIntrinsicWidth 74
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerSUoXv2PRN: React.ComponentType<Props> = withCSS(Component, css, "framer-yEoQP") as typeof Component;
export default FramerSUoXv2PRN;

FramerSUoXv2PRN.displayName = "Underline Animation";

FramerSUoXv2PRN.defaultProps = {height: 21, width: 74};

addFonts(FramerSUoXv2PRN, [{explicitInter: true, fonts: [{family: "Instrument Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npSQb_gfwmS0v3_7Y.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})